import React, { Component } from 'react';
import { compose } from 'redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import RouteNames from '../App/RouteNames';
import JobConfig from './JobConfig';
import JobSchedule from './JobSchedule';
import reducer from './reducer';
import saga from './saga';
import Footer from '../../components/Footer';
import Sidebar from './Sidebar';
import { makeGetPermissionsJobsManagement } from '../App/selectors';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';
import { getJobScheduleConfigByFrequency } from './actions';
import { makeGetScheduleList, makeGetDailyList } from './selectors';

class JobManagement extends Component {
  componentDidMount() {
    this.doGetJobConfig();
  }

  doGetJobConfig = () => {
    const { getJobScheduleConfigByFrequency } = this.props;
    getJobScheduleConfigByFrequency('DAILY');
    getJobScheduleConfigByFrequency('SCHEDULED');
  };

  render() {
    const { permissionsJob, dailyList, scheduleList } = this.props;
    let listJob = [];
    if (dailyList && dailyList.length) {
      listJob = [...listJob, ...dailyList];
    }
    if (scheduleList && scheduleList.length) {
      listJob = [...listJob, ...scheduleList];
    }

    if (!isHasPermissionWithMenu({ permissions: permissionsJob, listKeys: permissionList.jobsManagement })) return null;
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper job-management">
            <Switch>
              <Route exact path={RouteNames.jobsManagmentJobConfig.path} component={JobConfig} />
              <Route
                exact
                path={RouteNames.jobsManagmentJobSchedule.path}
                component={props => <JobSchedule {...props} />}
              />
              {listJob && listJob.length && (
                <Route
                  exact
                  path={RouteNames.jobsManagmentJobScheduleNonId.path}
                  render={() => (
                    <Redirect to={RouteNames.prettifyPath(RouteNames.jobsManagmentJobSchedule.path, listJob[0].id)} />
                  )}
                />
              )}
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  permissionsJob: makeGetPermissionsJobsManagement() || {},
  scheduleList: makeGetScheduleList() || {},
  dailyList: makeGetDailyList() || {},
});
const withConnect = connect(mapStateToProps, { getJobScheduleConfigByFrequency });

const withReducer = injectReducer({ key: 'jobManagementReducer', reducer });
const withSaga = injectSaga({ key: 'jobManagementSaga', saga });

export default compose(withReducer, withSaga, withConnect)(JobManagement);
